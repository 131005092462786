/**
 * [[`unit or equip name`, ID]]
 * 
 */

const NewsArticles = [
    {
        id: 0,
        date: `July 21, 2023`,
        article: 
        {
            intro: `Hey Summoners, we have yet another Collab on Japan. This time it is for Hell's Paradise.
                    Additionally, we revamped the Changelogs to be a bit cleaner. We also removed the Twitter because of the API changes.
                    The previous changelogs from before today's date will be removed, but in the future, any older changelogs will appear below the newest log.`,
            new: 
                {
                    units: 
                    [
                        [`Gabimaru`, 107797112], 
                        [`Sagiri`, 107807312]
                    ],
                    equips: 
                    [
                        [`画眉丸の服`, 152651],
                        [`佐切の刀`, 152671],
                        [`佐切の服`, 152681],
                        [`画眉丸の足袋`, 152661],
                        [`佐切の髪飾り`, 152701],
                        [`佐切の篭手`, 152691],
                        [`画眉丸のマスク`, 152631],
                        [`極『画眉丸のマスク`, 152641]
                    ]
                },
            globalUpdate:
                {
                    units: [],
                    equips: []
                }
        }
    },
    {
        id: 1,
        date: `July 27, 2023`,
        article: 
        {
            intro: `Hiya Summoners, Part 2 of the Fate/PRISMA☆ILLYA Collab has been officially released on the Global version, so you know what that means!
            Moreover, we have a new Summoner's Road stage being released on the Global version with 2 new 6☆ equips.`,
            new: 
                {
                    units: [],
                    equips: []
                },
            globalUpdate:
                {
                units: 
                    [
                        [`Miyu`, 107747512], 
                        [`Shirou Emiya`, 107777212]
                    ],
                    equips: 
                    [
                        [`True 'Magical Sapphire'`, 152431],
                        [`True 'Rho Aias'`, 152441],
                        [`Magical Sapphire`, 152451],
                        [`Gáe Bolg`, 152461],
                        [`Miyu's Rider Dress`, 152471],
                        [`Rho Aias`, 152481],
                        [`Shirou Emiya's Outfit`, 152491],
                        [`Miyu's Costume`, 152501],
                        [`Glacial Flail 'Dawn Devastator' Replica`, 111071],
                        [`Glacial Flail 'Dawn Devastator' Custom`, 111081]
                    ]
                }
        }
    },
    {
        id: 2,
        date: `July 31, 2023`,
        article: 
        {
            intro: `Hey Summoners, Part 2 of the Hell's Paradise Collab has been officially released on the Japanese version, alongside the 16th Floor of Elemental Ruins!
            We have a couple more units added to our database, and tons of equips.`,
            new: 
                {
                    units: 
                    [
                        [`Yuzuriha`, 107817512],
                        [`Chobei`, 107827412]
                    ],
                    equips: 
                    [
                        [`キルモンソード`, 111641],
                        [`デフモンシールド`, 111651],
                        [`タフモンロッド`, 111661],
                        [`杠の苦無`, 152711],
                        [`杠の簪`, 152721],
                        [`杠の竹筒`, 152731],
                        [`杠の服`, 152741],
                        [`弔兵衛の斧`, 152751],
                        [`弔兵衛の服`, 152761],
                        [`弔兵衛の首飾り`, 152771],
                        [`極『弔兵衛の首飾り』`, 152781]
                    ]
                },
            globalUpdate:
                {
                    units: [],
                    equips: []
                }
        }
    },
    {
        id: 3,
        date: `August 8, 2023`,
        article: 
        {
            intro: `Hey Summoners, Part 3 of the Fate/PRISMA☆ILLYA Collab has been officially released on the Global version, so more juicy units and equips have been updated with the Global tag!`,
            new: 
                {
                    units: 
                    [],
                    equips: 
                    []
                },
            globalUpdate:
                {
                    units: [
                        [`Chloe (Fate)`, 107757112],
                        [`Saber Alter`, 107787512]
                    ],
                    equips: [
                        [`True 'Kanshou & Bakuya'`, 152511],
                        [`True 'Excalibur Morgan'`, 152521],
                        [`Kanshou & Bakuya`, 152531],
                        [`Fake Nine Lives`, 152541],
                        [`Chloe's Outfit`, 152551],
                        [`Excalibur Morgan`, 152561],
                        [`Saber Alter's Armor`, 152571],
                        [`Caladbolg III`, 152581]
                    ]
                }
        }
    },
    {
        id: 4,
        date: `August 13, 2023`,
        article: 
        {
            intro: `JPGS Summer is back, and various Summer units have been buffed! Moreover, Elite Stiria has made its grand appearance in Mines there too.`,
            new: 
                {
                    units: 
                    [
                        [`Summer Juno`, 103376212],
                        [`Summer Leone`, 103386312],
                        [`Summer Cestina`, 103596112],
                        [`Summer Liza`, 103606212]
                    ],
                    equips: 
                    [
                        [`絶凍靴『フロストペイン』`, 111671],
                        [`真『シスのパッションフリル』`, 111761],
                        [`真『リザ姉の背伸びバンドゥ』`, 111771]
                    ]
                },
            globalUpdate:
                {
                    units: [],
                    equips: []
                }
        }
    },
    {
        id: 5,
        date: `August 18, 2023`,
        article: 
        {
            intro: `Aye~! It's Pirate season in JPGS. Along with a variety of new Summer equipment, the brand new Summer Riviera has been released! Additionally, a new Ultra Relic and 2 new Summoner's Road equipment have also been introduced!`,
            new: 
                {
                    units: 
                    [
                        [`Summer Riviera`, 103747112]
                    ],
                    equips: 
                    [
                        [`リヴィエラのヨーソローマント`, 111681],
                        [`ロゼッタインナー(スリム用)`, 111691],
                        [`さいきょー！ロゼッタアーム`, 111701],
                        [`レオーネのチャーミンググリーン`, 111711],
                        [`レオーネのエンジェルサンダル`, 111721],
                        [`リヴィエラのアンガーアンカー`, 111731],
                        [`リヴィエラ・ダイナマイト`, 111741],
                        [`封天鞭『スパインズ・ゴア』レプリカ`, 111781],
                        [`封天鞭『スパインズ・ゴア』アレンジ`, 111791],
                        [`Ultra Relic 'Frozen Twin Swords'`, 140141]
                    ]
                },
            globalUpdate:
                {
                    units: [],
                    equips: []
                }
        }
    },
    {
        id: 6,
        date: `August 24, 2023`,
        article: 
        {
            intro: `Wow, this is quite the update. Not only has Dream Awakening been introduced to Global for the first few units, 
            Act 3 of the New Legend of the Forbidden Ninja Scroll saga begins and introduces the next Ascended unit, Kirisame! 
            Moreover, prepare to face the new Ultra Luck Trial Event of Hakuro...
            
            Meanwhile, on the JP side, Summer Rosetta makes her grand debut, alongside her new farmable equipment. 
            Moreover, 3 new Dream Awakening units have been released as well!`,
            new: 
                {
                    units: [[`Summer Rosetta`, 103757512]],
                    equips: [[`GO！グレート・ガナルディア号`, 111751]],
                    dream: [
                        [`EDEN-typeΩ`, 100596412],
                        [`Yomi`, 100626212],
                        [`Vaid`, 100636312]
                    ]
                },
            globalUpdate:
                {
                    units: [
                        [`Kirisame`, 103527112],
                        [`Hakuro`, 103566311]
                    ],
                    equips: [
                        [`Sword God's Proof`, 111421],
                        [`Frozen Empress' Proof`, 111431],
                        [`Sword Emperor's Proof`, 111441],
                        [`True 'Garuda'`, 110621],
                        [`Kingblade 'Garuda'`, 110631],
                        [`Shinobi Shoes 'Sunika'`, 110641],
                        [`True 'Tenrui-Giri'`, 110651],
                        [`Hidden Dragon 'Tenrui-Giri'`, 110691],
                        [`Shinobi Guard 'Hojo'`, 110681]
                    ],
                    dream: [
                        [`Clyde`, 100016111],
                        [`Corsair`, 100026211],
                        [`Arth`, 100036311],
                        [`Roy`, 101036211],
                        [`Liese`, 101136411],
                        [`Zenon`, 101216512],
                    ]
                }
        }
    },
    {
        id: 7,
        date: `September 1, 2023`,
        article: 
        {
            intro: `Small update for Global with new Elemental Ruins equips, while Japan has released another unit with a new event story and some new Elemental Ruins equips.`,
            new: 
                {
                    units: [
                        [`Canary`, 103717212]
                    ],
                    equips: [
                        [`真『影斬り』`, 111801],
                        [`影斬り`, 111811],
                        [`ブルージャケット(カナリア仕様)`, 111821],
                        [`ゴルネコクラウン`, 111831],
                        [`メタチキティアラ`, 111841]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [
                        [`Red Flame Idol`, 110891],
                        [`Green Leaf Idol`, 110901],
                        [`Blue Drop Idol`, 110911]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 8,
        date: `September 7, 2023`,
        article: 
        {
            intro: `Crest Burst has finally made its grand appearance on the Global version, along with a brand new saga "Grim Stella & Great 6 Witches"! Moreover, we get some slight buffs to Daki's farmable equips too.`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [
                        [`Selfish Aria`, 109401],
                        [`Liz's Curio`, 109411],
                        [`Sorcerer's Treasured Robe 'Caligula'`, 105241],
                        [`Demon Twin-Blade 'Deino'`, 105251]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 9,
        date: `September 16, 2023`,
        article: 
        {
            intro: `Major update on JP! Tensura is back, and with it, brand new units and equips, and Buffs + True Weapons + Dream Awakening to pre-existing units!`,
            new: 
                {
                    units: [
                        [`Demon Lord Rimuru`, 107837512],
                        [`Milim: Battle Mode`, 107847112],
                        [`Diablo`, 107857512]
                    ],
                    equips: [
                        [`真『リムルの剣』`, 152791],
                        [`真『ミリムのネックレス』`, 152801],
                        [`真『ベニマルの剣』`, 152811],
                        [`真『リムルのサンタ服』`, 152821],
                        [`真『ミリムのサンタ服』`, 152831],
                        [`真『シオンのサンタ服』`, 152841],
                        [`真『リムルの羽織』`, 152851],
                        [`真『ミリムの着物』`, 152861],
                        [`真『魔王リムルの服』`, 152871],
                        [`真『ミリムの鎧』`, 152881],
                        [`真『ディアブロの服』`, 152891],
                        [`魔王リムルの服`, 152901],
                        [`ミリムの鎧`, 152911],
                        [`ディアブロの服`, 152921],
                        [`魔剣「天魔」`, 152931],
                        [`ソウエイの剣`, 152941],
                        [`ハクロウの剣`, 152951],
                    ],
                    dream: [
                        [`Rimuru (Human form)`, 107156212],
                        [`Milim`, 107166112],
                        [`Benimaru`, 107176112],
                        [`Santa Rimuru`, 107396212],
                        [`Santa Milim`, 107406112],
                        [`Santa Shion`, 107416512],
                        [`New Years Rimuru`, 107426512],
                        [`New Years Milim`, 107436412]
                    ]
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 10,
        date: `September 22, 2023`,
        article: 
        {
            intro: `Ninjas get a fresh set of buffs as well as Dream Awakenings on JP, along with some new equips. Meanwhile on the GL side, Hell's Paradise makes its grand debut with brand new units and equips!`,
            new: 
                {
                    units: [],
                    equips: [
                        [`現忍足『守弐韋袈』`, 111851],
                        [`ツヴァイトシーザ`, 111861]
                    ],
                    dream: [
                        [`Mizuki`, 102826212],
                        [`Ginzo`, 102836512],
                        [`Shiki`, 102846412],
                        [`Shuri`, 102856512],
                        [`Amane`, 102866412],
                        [`Kisaragi`, 102876112],
                        [`Saku`, 102886312],
                        [`Tamae`, 102896112]
                    ]
                },
            globalUpdate:
                {
                    units: [
                        [`Gabimaru`, 107797112],
                        [`Sagiri`, 107807312]
                    ],
                    equips: [
                        [`Gabimaru's Mask`, 152631],
                        [`Gabimaru's Mask EV`, 152641],
                        [`Gabimaru's Gi`, 152651],
                        [`Gabimaru's Tabi`, 152661],
                        [`Sagiri's Katana`, 152671],
                        [`Sagiri's Reifuku`, 152681],
                        [`Sagiri's Kote`, 152691],
                        [`Sagiri's Kamikazari`, 152701]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 11,
        date: `September 30, 2023`,
        article: 
        {
            intro: `The brand new Goddess Celia makes her grand appearance on JP along with Chapter 25! On the other hand, Floor 15 of Dark and Light Ruins will be added along with their respective equips on Global!`,
            new: 
                {
                    units: [
                        [`Goddess Celia`, 103767412]
                    ],
                    equips: [
                        [`真『シャングリラ』`, 111871],
                        [`天耀剣『シャングリラ』`, 111881],
                        [`煌玉装『エメロディーテ』`, 111891]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [
                        [`Violet Murk Idol`, 111011],
                        [`Golden Shrine Idol`, 111021]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 12,
        date: `October 6, 2023`,
        article: 
        {
            intro: `Part 2 of Hell's Paradise is out on Global, bringing along with it a number of new units and equips... Check them out below!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Yuzuriha`, 107817512],
                        [`Chobe`, 107827412]
                    ],
                    equips: [
                        [`Yuzuriha's Kunai`, 152711],
                        [`Yuzuriha's Kanzashi`, 152721],
                        [`Yuzuriha's Takezutsu`, 152731],
                        [`Yuzuriha's Noragi`, 152741],
                        [`Chobe's Axe`, 152751],
                        [`Chobe's Ascetic Outfit`, 152761],
                        [`Chobe's Necklace`, 152771],
                        [`Chobe's Necklace EV`, 152781]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 13,
        date: `October 16, 2023`,
        article: 
        {
            intro: `Spooky season is just around the corner... A fresh set of Halloween-themed Units and Equips have made their appearance in the JP version! Moreover, Abaddon and Ragna get their very own Dream Awakening!`,
            new: 
                {
                    units: [
                        [`Halloween Charlotte`, 103777112],
                        [`Halloween Valerie`, 103787212],
                        [`Halloween Forte`, 103797512]
                    ],
                    equips: [
                        [`シャルのガオおてて`, 111901],
                        [`シャルのガオおみあし`, 111911],
                        [`トリック・オア・ダイ`, 111921],
                        [`フォルテのドーリィドレス`, 111931],
                        [`ヴァレリーのまじりざステッキ`, 111941],
                        [`フォルテのちっちゃいぼうし`, 111951]
                    ],
                    dream: [
                        [`Abaddon`, 100606512],
                        [`Ragna`, 100616112]
                    ]
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 14,
        date: `October 20, 2023`,
        article: 
        {
            intro: `Spooky season arrives on Global! Brand new Halloween-themed equips have been released as well as a brand new Ascended unit Hazuki with her very own farmable equips!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Hazuki`, 103547412]
                    ],
                    equips: [
                        [`Rusty Waitress Marika`, 110701],
                        [`Field Medic Forte`, 110711],
                        [`Fantom Filcher Fen`, 110721],
                        [`Ruffhousing Lotte`, 110731],
                        [`Magical Muscles Valerie`, 110741],
                        [`Shepherd of Isliid`, 110751],
                        [`True 'Jurai'`, 110801],
                        [`Chainblade 'Jurai'`, 110811],
                        [`Shinobi Haori 'Gaun'`, 110821]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 15,
        date: `October 25, 2023`,
        article: 
        {
            intro: `Super small update for the JP side. Along with a new difficulty to Jack-o-Sherum raid, Jack-o-Sherum Plushie EV can now be crafted using the Halloween Raid materials!`,
            new: 
                {
                    units: [],
                    equips: [
                        [`極『カボシェルムのぬいぐるみ』`, 111961]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 16,
        date: `November 2, 2023`,
        article: 
        {
            intro: `A big update for both GL and JP versions! While GL introduces Dream Awakening and buffs for Goblin Slayer units with its rerun, JP's latest Main Story chapter introduces a new Emperor Isliid along with some new equips as well.`,
            new: 
                {
                    units: [
                        [`Beyond Fate, Emperor Isliid`, 103807412]
                    ],
                    equips: [
                        [`真『ルーンマスター』』`, 111971],
                        [`真『フォルコメンドライ』`, 111981],
                        [`フォルコメンドライ・レプリカ`, 111991],
                        [`ルーンマスター・レプリカ`, 112001]

                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: [
                        [`Goblin Slayer`, 107186512],
                        [`Priestess`, 107196412],
                        [`High Elf Archer`, 107206312]
                    ]
                }
        }
    },
    {
        id: 17,
        date: `November 15, 2023`,
        article: 
        {
            intro: `Evil Pola makes her grand appearance on JP, along with some new farmable equips as well. Meanwhile, Chapter 21 has been released on Global, featuring Demon Edge Berwick, his farmable equips, as well as a new Secret equip!`,
            new: 
                {
                    units: [
                        [`Evil Pola`, 103817312]
                    ],
                    equips: [
                        [`真『ラディカルソード』`, 112011],
                        [`ラディカルソード`, 112021],
                        [`セルフィードレス`, 112031]

                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Demon Edge Berwick`, 103636112]
                    ],
                    equips: [
                        [`True 'Enmon Zanketsu'`, 110761],
                        [`Enmon Zanketsu`, 110771],
                        [`Simmering Mantle`, 110781],
                        [`Fleetfire Greaves`, 110791]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 18,
        date: `November 22, 2023`,
        article: 
        {
            intro: `Small update on the JP side with Ragsherum getting his own Dream Awakening, as well as a new Mines boss!`,
            new: 
                {
                    units: [],
                    equips: [
                        [`蒼機盾『ツヴァイトシルト』`, 112041]
                    ],
                    dream: [
                        [`Ragsherum`, 101086511]
                    ]
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 19,
        date: `November 30, 2023`,
        article: 
        {
            intro: `Demon Lord Vox makes his debut on Global with an exclusive SP. Art, alongside Dream Awakenings for both Mira and Zeorg! Meanwhile on JP, Holy Knight Roy also makes his debut as JP's 7th Anniversary unit!`,
            new: 
                {
                    units: [
                        [`Holy Knight Roy`, 103827212]
                    ],
                    equips: [
                        [`真『グラディウス』`, 112051],
                        [`聖剣『グラディウス』`, 112061],
                        [`聖装『アイオロス』`, 112071]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Demon Lord Vox`, 103697512]
                    ],
                    equips: [
                        [`True 'Maken Zanketsu`, 111361],
                        [`Maken Zanketsu`, 111371],
                        [`Netherlord Armor 'Beelzedia'`, 111381]
                    ],
                    dream: [
                        [`Mira (Fire)`, 101046111],
                        [`Zeorg (Light)`, 101066411]
                    ]
                }
        }
    },
    {
        id: 20,
        date: `December 9, 2023`,
        article: 
        {
            intro: `Small update for Global with the release of a new farmable Christmas equip!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [
                        [`Yule Staff 'Merecrimus'`, 110971]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 21,
        date: `December 15, 2023`,
        article: 
        {
            intro: `An early Christmas? The Tensura collab makes its return on Global with 3 new units and many new equips, as well as various buffs to pre-exisiting Tensura units. Meanwhile, Ganan receives a Dream Awakening on JP.`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: [
                        [`Ganan`, 101116111]
                    ]
                },
            globalUpdate:
                {
                    units: [
                        [`Demon Lord Rimuru`, 107837512],
                        [`Combat Form Milim`, 107847112],
                        [`Diablo`, 107857512]
                    ],
                    equips: [
                        [`True 'Rimuru's Sword'`, 152791],
                        [`True 'Milim's Necklace'`, 152801],
                        [`True 'Benimaru's Sword'`, 152811],
                        [`True 'Rimuru's Mantle'`, 152871],
                        [`True 'Milim's Armor'`, 152881],
                        [`True 'Diablo's Suit'`, 152891],
                        [`Rimuru's Mantle`, 152901],
                        [`Milim's Armor`, 152911],
                        [`Diablo's Suit`, 152921],
                        [`God-Grade Sword 'Asura'`, 152931],
                        [`Souei's Blade`, 152941],
                        [`Hakurou's Cane Katana`, 152951]
                    ],
                    dream: [
                        [`Rimuru (Slime form)`, 107146212],
                        [`Rimuru (Human form)`, 107156212],
                        [`Milim`, 107166112],
                        [`Benimaru`, 107176112],
                    ]
                }
        }
    },
    {
        id: 22,
        date: `December 21, 2023`,
        article: 
        {
            intro: `Shangri-La is the next newest Collab on JP, bringing with it brand new units and equips!`,
            new: 
                {
                    units: [
                        [`Sunraku`, 107867112],
                        [`Psyger-0`, 107877412]
                    ],
                    equips: [
                        [`致命の包丁`, 152961],
                        [`極『致命の包丁』`, 152971],
                        [`致命魂の首輪`, 152981],
                        [`神魔の大剣`, 152991],
                        [`双貌の兜`, 153001]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 23,
        date: `December 26, 2023`,
        article: 
        {
            intro: `New Christmas equips have been introduced to JP!`,
            new: 
                {
                    units: [],
                    equips: [
                        [`聖飾剣『スターライト』`, 112081],
                        [`忠勇無双ノエルトナカイ`, 112091],
                        [`全知全能陛下サンタ`, 112101],
                        [`愛と雪の公女`, 112111],
                        [`聖夜の狩人ジェイサンタ`, 112121],
                        [`狩人の監視者カナリアトナカイ`, 112131]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 24,
        date: `December 29, 2023`,
        article: 
        {
            intro: `Christmas Tensura units have been buffed, along with a new Raid featuring Palamicia with her unique Raid equip. Moreover, although he isn't officially out yet, Quickfang Jay has been announced as the next newest addition!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Jay`, 103707212]
                    ],
                    equips: [
                        [`True 'Rimuru's Santa Suit'`, 152821],
                        [`True 'Milim's Santa Coat'`, 152831],
                        [`True 'Shion's Santa Dress'`, 152841],
                        [`True 'Neon Bayonet'`, 111391],
                        [`Silent Ritual`, 109001],
                        [`Silent Ritual EV`, 109011],
                    ],
                    dream: [
                        [`Santa Rimuru`, 107396212],
                        [`Santa Milim`, 107406112],
                        [`Santa Shion`, 107416512]
                    ]
                }
        }
    },
    {
        id: 25,
        date: `January 1, 2024`,
        article: 
        {
            intro: `Happy New Year! Part 2 of Shangri-La Frontier Collab debuts on JP, featuring 2 brand new units and a few new equips as well. Moreover, a new Groitz Raid boss has also made an appearance.`,
            new: 
                {
                    units: [
                        [`Arthur Pencilgon`, 107887512],
                        [`OiKatzo`, 107897112]
                    ],
                    equips: [
                        [`マッドネスサイス`, 112141],
                        [`ペンシルゴンのイヤリング`, 153011],
                        [`極『ペンシルゴンのイヤリング』`, 153021],
                        [`ペンシルゴンの槍`, 153031],
                        [`拳気まといの拳`, 153041],
                        [`修験の胴衣`, 153051]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 26,
        date: `January 16, 2024`,
        article: 
        {
            intro: `More Dream Awakenings for both Global and JP versions. Moreover, both versions have also added new equips as shown below!`,
            new: 
                {
                    units: [],
                    equips: [
                        [`絶甲盾『アズルヴァイト』`, 112151],
                        [`情熱装『スクレパシオン』`, 112161],
                        [`Ultra Relic 'Diablos'`, 140151],
                    ],
                    dream: [
                        [`Berwick (Magic)`, 102486112],
                        [`Riviera`, 102496312],
                        [`Vox`, 102516212],
                        [`Berwick (Physical)`, 102666112],
                        [`Leone`, 102686412]
                    ]
                },
            globalUpdate:
                {
                    units: [],
                    equips: [
                        [`True 'Rimuru's Haori'`, 152851],
                        [`True 'Milim's Kimono'`, 152861]
                    ],
                    dream: [
                        [`New Years Rimuru`, 107426512],
                        [`New Years Milim`, 107436412]
                    ]
                }
        }
    },
    {
        id: 27,
        date: `January 24, 2024`,
        article: 
        {
            intro: `New unit Marlet makes her appearance on JP, along with her related equips. Meanwhile, 3 new Dream Awakenings, new Raid equips, and a whole bunch of new 6* Equips from Summoner's Road have been introduced to Global!`,
            new: 
                {
                    units: [
                        [`Marlet`, 103837412]
                    ],
                    equips: [
                        [`真『ヴェスパーダ』`, 112171],
                        [`煌華銃『ヴェスパーダ』`, 112181],
                        [`華麗装『ブルヴェスティート』`, 112191],
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [
                        [`Halos Pragma`, 109301],
                        [`Halos Pragma EV`, 109311],
                        [`Twinflame Helm 'Garmroar' Replica`, 111131],
                        [`Twinflame Helm 'Garmroar' Custom`, 111141],
                        [`Iceshard Shield 'Aeruscale' Replica`, 111151],
                        [`Iceshard Shield 'Aeruscale' Custom`, 111161],
                        [`Verdant Bracers 'Elpdaron' Replica`, 111171],
                        [`Verdant Bracers 'Elpdaron' Custom`, 111181],
                        [`Gleaming Crux 'Fosbrace' Replica`, 111261],
                        [`Gleaming Crux 'Fosbrace' Custom`, 111271],
                        [`Hades Tome 'Plutorix' Replica`, 111281],
                        [`Hades Tome 'Plutorix' Custom`, 111291]
                    ],
                    dream: [
                        [`Feena`, 100046411],
                        [`Regulus`, 100056511],                        
                        [`Ashe Toto`, 101196312]
                    ]
                }
        }
    },
    {
        id: 28,
        date: `February 1, 2024`,
        article: 
        {
            intro: `Although Chapter 28 has been slightly delayed along with its equips, Fictional Mage Hart makes his debut in JP! Moreover, F17 Fire, Earth, Water Ruins equips have also been revealed for JP. Meanwhile on Global, Part 1 of Shangri-La Frontier collab has been confirmed, bringing with it a plethora of new units and equips!`,
            new: 
                {
                    units: [
                        [`Fictional Mage Hart`, 103847512]
                    ],
                    equips: [
                        [`真『アスガルドの柩』`, 112201],
                        [`火竜神の剣`, 112231],
                        [`水竜神の耳飾り`, 112241],
                        [`樹竜神の盾`, 112251]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Sunraku`, 107867112],
                        [`Psyger-0`, 107877412]
                    ],
                    equips: [
                        [`Vorpal Chopper`, 152961],
                        [`Vorpal Chopper EV`, 152971],
                        [`Vorpal Soul Collar`, 152981],
                        [`Antimony`, 152991],
                        [`Two-Faced Helmet`, 153001]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 29,
        date: `February 6, 2024`,
        article: 
        {
            intro: `Small update this time for JP. Chapter 28 has been officially released after the initial delay, bringing with it 2 new equips!`,
            new: 
                {
                    units: [],
                    equips: [
                        [`アスガルドの柩`, 112211],
                        [`古礼靴 『ブレンフェイト』`, 112221]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 30,
        date: `February 14, 2024`,
        article: 
        {
            intro: `A new Valentines unit has been introduced in JP, along with some new Dream Awakenings for older units!`,
            new: 
                {
                    units: [
                        [`Liza (Valentines)`, 103857312]
                    ],
                    equips: [
                        [`綺想箒『エトワールV』`, 112261]
                    ],
                    dream: [
                        [`Gilliam`, 101126311],
                        [`Elfalla`, 100646412],
                        [`Lily`, 100656512]
                    ]
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 31,
        date: `February 17, 2024`,
        article: 
        {
            intro: `Part 2 of the Shangri-La Frontier collab has begun, bringing with it new units and equips!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Arthur Pencilgon`, 107887512],
                        [`OiKatzo`, 107897112]
                    ],
                    equips: [
                        [`Pencilgon's Earrings`, 153011],
                        [`Pencilgon's Earrings EV`, 153021],
                        [`Pencilgon's Lance`, 153031],
                        [`Spirit Fist Gloves`, 153041],
                        [`Acolyte's Vest`, 153051],
                    ],
                    dream: []
                }
        }
    },
    {
        id: 32,
        date: `February 23, 2024`,
        article: 
        {
            intro: `Small update on JP, introducing a new Valentine's unit as well as a new Raid equip!`,
            new: 
                {
                    units: [
                        [`Cestina (Valentines)`, 103867212]
                    ],
                    equips: [
                        [`焔竜兜『デグルロア』`, 112271]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 33,
        date: `February 27, 2024`,
        article: 
        {
            intro: `Valentines Miranda has come to Global, alongside her farmable equips and 2 new Dream Awakenings!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Miranda (Valentines)`, 103667412]
                    ],
                    equips: [
                        [`Miranda's Adolescence`, 111091],
                        [`Miranda's Fantabulous Cloak`, 111101],
                    ],
                    dream: [
                        [`Melia (Dark)`, 101076511],
                        [`Dargeon`, 100566112]
                    ]
                }
        }
    },
    {
        id: 34,
        date: `March 1, 2024`,
        article: 
        {
            intro: `Huge update on JP with the rerun of the Overlord collab, introducing buffs to existing OL units as well as brand new OL Units and Equips! Oh, and also 2 new Dark and Light Ruins F17 equips.`,
            new: 
                {
                    units: [
                        [`Momon`, 107907512],
                        [`Demiurge`, 107917112],
                        [`Evileye`, 107927312]
                    ],
                    equips: [
                        [`光竜神の槍`, 112281],
                        [`闇竜神の斧`, 112291],
                        [`モモンズ・グレートソード`, 153061],
                        [`漆黒の全身鎧`, 153071],
                        [`イルアン・グライベル`, 153081],
                        [`ネクロプラズミックマント`, 153091],
                        [`加速の靴`, 153101],
                        [`デミウルゴスのスーツ`, 153111],
                        [`デミウルゴスの眼鏡`, 153121],
                        [`イビルアイのローブ`, 153141],
                        [`イビルアイの仮面`, 153151],
                        [`True 'Staff of Ainz Ooal Gown'`, 153161],
                        [`True '3F'`, 153171],
                        [`True 'Pipette Lance'`, 153181],
                        [`True 'Keraunos Mk-III'`, 153191],
                        [`真『モモンズ・グレートソード』`, 153201],
                        [`真『デミウルゴスのスーツ』`, 153211],
                        [`真『イビルアイの仮面』`, 153221],
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 35,
        date: `March 9, 2024`,
        article: 
        {
            intro: `A new farmable equip is introduced from the Overlord rerun in JP. Meanwhile on Global, Valentines Priscilla makes her debut with her SP. Art and farmable equips, alongside 2 new Dream Awakenings!`,
            new: 
                {
                    units: [],
                    equips: [
                        [`デミウルゴスのグローブ`, 153131]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Priscilla (Valentines)`, 103677112]
                    ],
                    equips: [
                        [`Priscilla's Cupid Brand`, 111111],
                        [`Priscilla's Dreamy Apron`, 111121]
                    ],
                    dream: [
                        [`Angelas`, 103066112],
                        [`Eagle`, 103076312]
                    ]
                }
        }
    },
    {
        id: 36,
        date: `March 14, 2024`,
        article: 
        {
            intro: `Small update, Edram finally gets his Awakening in JP!`,
            new: 
                {
                    units: [
                        [`Edram`, 101576412]
                    ],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 37,
        date: `March 21, 2024`,
        article: 
        {
            intro: `New Raid equips and a new Sublime Supernova Liza SP. Art have been introduced to JP. Meanwhile on Global, Sublime Supernova Liza makes her debut with her SP. Art and faarmable equips. Moreover, 3 new Dream Awakenings too!`,
            new: 
                {
                    units: [],
                    equips: [
                        [`極『アネモス』`, 112301],
                        [`蒼翼剣『レイダルファング』`, 112311]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Sublime Supernova Liza`, 103647412]
                    ],
                    equips: [
                        [`True 'Mercurius'`, 110851],
                        [`Sainted Staff 'Mercurius'`, 110861],
                        [`Sagacious Robe 'Cathedral'`, 110871]
                    ],
                    dream: [
                        [`Herck`, 100576212],
                        [`Valhalla`, 100586312],
                        [`Arosdea`, 101176112]
                    ]
                }
        }
    },
    {
        id: 38,
        date: `March 31, 2024`,
        article: 
        {
            intro: `Part 4 of the Blue Jacket saga introduces Lark as the newest JP unit, along with his various equips!`,
            new: 
                {
                    units: [
                        [`Lark`, 103877112]
                    ],
                    equips: [
                        [`真『斬塵如火』`, 112321],
                        [`緋燕刀『斬塵如火』`, 112331]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 39,
        date: `April 6, 2024`,
        article: 
        {
            intro: `New Dream Awakenings come out for JP. Meanwhile, Fairytail reruns in Global, featuring Dream Awakenings and SP. Art for each respective collab unit!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: [
                        [`Orvell`, 103026412],
                        [`Luahn`, 103046512],
                        [`Bakoo`, 103086512]
                    ]
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: [
                        [`Natsu Dragneel`, 107556112],
                        [`Lucy Heartfilia`, 107566412],
                        [`Gray Fullbuster`, 107576212],
                        [`Erza Scarlet`, 107586412]
                    ]
                }
        }
    },
    {
        id: 40,
        date: `April 12, 2024`,
        article: 
        {
            intro: `Small update with JP getting a new mines boss - Marzex, along with his unique equip drop as well.`,
            new: 
                {
                    units: [],
                    equips: [
                        [`天元甲『アルヴェーダ』`, 112341]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 41,
        date: `April 16, 2024`,
        article: 
        {
            intro: `Another small update, albeit for Global this time. Aristela Orbis is the newest Ascended unit, releasing alongside her event with new farmables! Moreover, 3 new Dream Awakenings! Also, SP. Art for both Gray and Erza have been officially released too!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Aristela Orbis`, 103687212]
                    ],
                    equips: [
                        [`True 'Happiness Trigger'`, 111231],
                        [`Paradise Needle 'Happiness Trigger'`, 111241],
                        [`Wrenchcoat 'Mind Cracker'`, 111251]
                    ],
                    dream: [
                        [`EDEN-typeΩ`, 100596412],
                        [`Yomi`, 100626212],
                        [`Vaid`, 100636312]
                    ]
                }
        }
    },
    {
        id: 42,
        date: `April 20, 2024`,
        article: 
        {
            intro: `Elenore, or rather Alma in a bikini, has made her debut on JP alongside her event farmable equip!`,
            new: 
                {
                    units: [
                        [`Elenore`, 103886312]
                    ],
                    equips: [
                        [`真『フェスタリオン』`, 112351],
                        [`魔刻杖『フェスタリオン』`, 112361]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 43,
        date: `April 28, 2024`,
        article: 
        {
            intro: `Chapter 23 has been released to Global, alongside Noble Flare Mira and her equips! Moreover, Ragna and Abaddon have received their Dream Awakenings.`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Noble Flare Mira`, 103657112]
                    ],
                    equips: [
                        [`True 'Braise de Bamint'`, 111191],
                        [`Searing Blade 'Braise de Bamint'`, 111201],
                        [`Petal Cloth 'Mirage de Bamint'`, 111211]
                    ],
                    dream: [
                        [`Abaddon`, 100606512],
                        [`Ragna`, 100616112]
                    ]
                }
        }
    },
    {
        id: 44,
        date: `May 7, 2024`,
        article: 
        {
            intro: `Big update for JP with new units and equips introduced with the KLK Collab Part 1 rerun. Moreover, Sophie has finally received her Dream Awakening!`,
            new: 
                {
                    units: [
                        [`Uzu Sanageyama`, 107937312],
                        [`Houka Inumuta`, 107947212]
                    ],
                    equips: [
                        [`蟇郡 苛の車`, 153231],
                        [`極『蟇郡 苛の車』`, 153241],
                        [`猿投山 渦の極制服`, 153251],
                        [`犬牟田 宝火の極制服`, 153261],
                        [`猿投山 渦の三都制圧襲学旅行ジャージ`, 153291],
                        [`犬牟田 宝火の三都制圧襲学旅行ジャージ`, 153301],
                        [`猿投山 渦の目隠し`, 153331],
                        [`犬牟田 宝火の眼鏡`, 153341],
                        [`真『猿投山 渦の極制服』`, 153361],
                        [`真『犬牟田 宝火の極制服』`, 153371]
                    ],
                    dream: [
                        [`Mako`, 102206412],
                        [`Satsuki`, 102216212],
                        [`Ryuko`, 102226112],
                        [`Nui Harime`, 107596512],
                        [`Sophie`, 101156111]
                    ]
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 45,
        date: `May 11, 2024`,
        article: 
        {
            intro: `A new Raid has been released in Global with new equips, alongside a new farmable equip in Melia's Event! Moreover, a new Rainbow Summoners' Road has made its debut with 2 new 6☆ equips to obtain!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [
                        [`Genocider's Helm`, 109901],
                        [`Genocider's Helm EV`, 109941],
                        [`Fox Raiment 'Ravissante'`, 108231],
                        [`Celestial Vessel 'Zexraster' Replica`, 111621],
                        [`Celestial Vessel 'Zexraster' Custom`, 111631]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 46,
        date: `May 14, 2024`,
        article: 
        {
            intro: `Part 2 of the KLK Collab rerun has been released, bringing with it 2 new units and a variety of new KLK equips as well!`,
            new: 
                {
                    units: [
                        [`Ira Gamagori`, 107957512],
                        [`Nonon Jakuzure`, 107967412]
                    ],
                    equips: [
                        [`蟇郡 苛の極制服`, 153271],
                        [`蛇崩 乃音の極制服`, 153281],
                        [`蟇郡 苛の三都制圧襲学旅行ジャージ`, 153311],
                        [`蛇崩 乃音の三都制圧襲学旅行ジャージ`, 153321],
                        [`蛇崩 乃音の帽子`, 153351],
                        [`真『蟇郡 苛の極制服』`, 153381],
                        [`真『蛇崩 乃音の極制服』`, 153391]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 47,
        date: `May 18, 2024`,
        article: 
        {
            intro: `The Overlord Collab has returned to Global, introducing 3 new Ascended Overlord units as well as various buffs and True Weapons for the 4 original Overlord units. As usual, there are also many new Overlord themed equips too!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Momon`, 107907512],
                        [`Demiurge`, 107917112],
                        [`Evileye`, 107927312]
                    ],
                    equips: [
                        [`Momon's Greatsword`, 153061],
                        [`Dark Plate Armor`, 153071],
                        [`Járngreipr`, 153081],
                        [`Necroplasmic Mantle`, 153091],
                        [`Haste Boots`, 153101],
                        [`Demiurge's Suit`, 153111],
                        [`Demiurge's Glasses`, 153121],
                        [`Lord of First Invern`, 153141],
                        [`Evileye's Mask`, 153151],
                        [`True 'Staff of Ainz Ooal Gown'`, 153161],
                        [`True '3F'`, 153171],
                        [`True 'Pipette Lance'`, 153181],
                        [`True 'Keraunos Mk-III'`, 153191],
                        [`True 'Momon's Greatsword'`, 153201],
                        [`True 'Demiurge's Suit'`, 153211],
                        [`True 'Evileye's Mask'`, 153221]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 48,
        date: `May 22, 2024`,
        article: 
        {
            intro: `Minor update this time as JP has gotten a new Raid boss with a new equip!`,
            new: 
                {
                    units: [],
                    equips: [
                        [`燐光套『フォスブリオ』`, 112371]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 49,
        date: `May 31, 2024`,
        article: 
        {
            intro: `Small update on GL with Part 2 of the Overlord collab introducing a new farmable equip. Moreover, Ragsherum has received his Dream Awakening!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [
                        [`Demiurge's Gloves`, 153131]
                    ],
                    dream: [
                        [`Ragsherum`, 101086511]
                    ]
                }
        }
    },
    {
        id: 50,
        date: `June 1, 2024`,
        article: 
        {
            intro: `Chapter 29 has been released in JP, alongside a new unit and equips!`,
            new: 
                {
                    units: [
                        [`Star Swordswoman Valerie`, 103897212]
                    ],
                    equips: [
                        [`真『ライジング・ノヴァ』`, 112381],
                        [`星剣『ライジング・ノヴァ』`, 112391]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 51,
        date: `June 10, 2024`,
        article: 
        {
            intro: `A second new unit has been released from Chapter 29 in JP, Velzard, alongside his farmable equip and even a new Secret Equip!`,
            new: 
                {
                    units: [
                        [`Velzard`, 103907112]
                    ],
                    equips: [
                        [`真『ディープ・ブラッド』`, 112401],
                        [`血鎌『ディープ・ブラッド』`, 112411]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 52,
        date: `June 13, 2024`,
        article: 
        {
            intro: `A new Ninja, Long, has made his debut on GL, alongside Barossa and Dream Awakenings for other existing Ninjas!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Long`, 103557212],
                        [`Barossa`, 102816512]
                    ],  
                    equips: [
                        [`True 'Yamata'`, 111041],
                        [`Coldsteel Claws 'Yamata'`, 111051],
                        [`Cerulean Platemail 'Wrathscale'`, 111061],
                        [`True 'Shadow Stella'`, 106521]
                    ],
                    dream: [
                        [`Mizuki`, 102826212],
                        [`Ginzo`, 102836512],
                        [`Kisaragi`, 102876112],
                        [`Saku`, 102886312]
                    ]
                }
        }
    },
    {
        id: 53,
        date: `June 26, 2024`,
        article: 
        {
            intro: `A new unit is introduced alongside the new content of Grand Battle in JP. Meanwhile on Global, Chapter 24 has been released with Luana and a bunch of Ninja Dream Awakenings!`,
            new: 
                {
                    units: [
                        [`Azuel`, 103917112]
                    ],
                    equips: [
                        [`真『ツイン・ファルコン』`, 112421]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Luana`, 103337412]
                    ],  
                    equips: [
                        [`True 'Millenium Ark'`, 111491],
                        [`Aged Crystal 'Millenium Arc'`, 111501],
                        [`Ribnald Beret`, 111511],
                        [`Spirit Steps 'Elements Row'`, 111521],
                        [`Kilmon Sword`, 111641],
                        [`Defmon Shield`, 111651],
                        [`Tufmon Rod`, 111661],
                    ],
                    dream: [
                        [`Shiki`, 102846412],
                        [`Shuri`, 102856512],
                        [`Amane`, 102866412],
                        [`Tamae`, 102896112]
                    ]
                }
        }
    },
    {
        id: 54,
        date: `June 28, 2024`,
        article: 
        {
            intro: `Fate Collab is back in JP, but this time with a Summer twist!`,
            new: 
                {
                    units: [
                        [`Summer Chloe`, 107997412],
                        [`Summer Bazett`, 108007212],
                    ],
                    equips: [
                        [`真『クロエの水着』`, 153421],
                        [`真『バゼットの水着』`, 153441],
                        [`想い出のブレスレット`, 153471],
                        [`クロエの水着`, 153541],
                        [`クロエのスイムワンピ`, 153551],
                        [`バゼットの水着`, 153581],
                        [`バゼットのエプロン`, 153591],
                        [`斬り抉る戦神の剣`, 153601]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 55,
        date: `July 6, 2024`,
        article: 
        {
            intro: `OPM Collab is back on Global with new Dream Awakenings as well as buffs for all the OPM units below!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Saitama`, 107076412],
                        [`Genos`, 107086512],
                        [`Terrible Tornado`, 107096312],
                        [`Boros`, 107106511],
                        [`Sonic`, 107216512],
                        [`Hellish Blizzard`, 107226212],
                        [`Garou`, 107236512]
                    ],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 56,
        date: `July 11, 2024`,
        article: 
        {
            intro: `Part 2 of the Fate Collab Rerun is out, featuring 2 new Summer units as well as new equips!`,
            new: 
                {
                    units: [
                        [`Summer Miyu`, 107987112],
                        [`Summer Luvia & Rin`, 108017312],
                    ],
                    equips: [
                        [`真『美遊の水着』`, 153411],
                        [`真『ルヴィアの水着』`, 153431],
                        [`穂群原学園小等部の夏制服`, 153481],
                        [`破戒すべき全ての符`, 153501],
                        [`美遊の水着`, 153521],
                        [`美遊の麦わら帽子`, 153531],
                        [`遠坂凛の水着`, 153561],
                        [`ルヴィアの水着`, 153571],
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 57,
        date: `July 17, 2024`,
        article: 
        {
            intro: `Anti-Heroine Pola makes her debut in Global with her respective event equips!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Anti-Heroine Pola`, 103817312]
                    ],  
                    equips: [
                        [`True 'Radical Edge'`, 112011],
                        [`Radical Edge`, 112021],
                        [`Center of Attention`, 112031]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 58,
        date: `July 24, 2024`,
        article: 
        {
            intro: `Part 3 of JP's Fate Summer collab rerun has been released, bringing with it new units and equips!`,
            new: 
                {
                    units: [
                        [`Summer Illya`, 107977212],
                        [`Summer Sakura Matou`, 108027512]
                    ],
                    equips: [
                        [`真『イリヤの水着』`, 153401],
                        [`真『間桐桜の水着』`, 153451],
                        [`イリヤの水着`, 153461],
                        [`ヘカテの錫杖`, 153491],
                        [`アレストチェーン`, 153511],
                        [`間桐桜の水着`, 153611],
                        [`間桐桜の仮面`, 153621],
                        [`間桐桜の制服`, 153631]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 59,
        date: `July 26, 2024`,
        article: 
        {
            intro: `Kokuri makes her debut on Global, alongside her respective equips. Moreover, some Summer variant units have been buffed!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Kokuri`, 103537112],
                        [`Summer Juno`, 103376212],
                        [`Summer Leone`, 103386312],
                        [`Summer Cestina`, 103596112],
                        [`Summer Liza`, 103606212]
                    ],  
                    equips: [
                        [`True 'Kubou'`, 111301],
                        [`Conflagrant Pearls 'Kubou'`, 111311],
                        [`Magical Garb 'Ruban le Lien'`, 111321],
                        [`Golcat King Crown`, 111831],
                        [`Metal Chicken Tiara`, 111841]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 60,
        date: `August 10, 2024`,
        article: 
        {
            intro: `Grad can finally Dream Awaken in JP and Kill La Kill collab rerun has arrived in Global, bringing a bunch of new units and equips, as well as buffs to the exisitng cast of Kill La Kill units!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: [
                        [`Grad`, 101096511]
                    ]
                },
            globalUpdate:
                {
                    units: [
                        [`Uzu Sanageyama`, 107937312],
                        [`Houka Inumuta`, 107947212]
                    ],  
                    equips: [
                        [`Ira's Hot Rod`, 153231],
                        [`Ira's Hot Rod EV`, 153241],
                        [`Blade Regalia`, 153251],
                        [`Probe Regalia`, 153261],
                        [`Uzu's Raid Tracksuit`, 153291],
                        [`Houka's Raid Tracksuit`, 153301],
                        [`Uzu's Eye Mask`, 153331],
                        [`Houka's Eyeglasses`, 153341],
                        [`True 'Blade Regalia'`, 153361],
                        [`True 'Probe Regalia'`, 153371],
                    ],
                    dream: [
                        [`Mako`, 102206412],
                        [`Satsuki`, 102216212],
                        [`Ryuko`, 102226112],
                        [`Nui Harime`, 107596512]
                    ]
                }
        }
    },
    {
        id: 61,
        date: `August 17, 2024`,
        article: 
        {
            intro: `Small update on JP, featuring new Dream Awakenings for 4 Summer unit variants as well as a new Mines equip from Omega Marzex!`,
            new: 
                {
                    units: [],
                    equips: [
                        [`絶元甲『ゼクスヴェーダ』`, 112431]
                    ],
                    dream: [
                        [`Summer Juno`, 103376212],
                        [`Summer Leone`, 103386312],
                        [`Summer Cestina`, 103596112],
                        [`Summer Liza`, 103606212]
                    ]
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 62,
        date: `August 25, 2024`,
        article: 
        {
            intro: `Chapter 30 is out in JP, alongside Sword God Luahn and his respective equips! On Global, Part 2 of the KLK collab rerun is out, introducing new units and equips. Moreover, Summer Fen has also made his debut with an exclusive SP. Art!`,
            new: 
                {
                    units: [
                        [`Sword God Luahn`, 103997512]
                    ],
                    equips: [
                        [`真『白玄両儀』`, 112441],
                        [`龍神刀『白玄両儀』`, 112451],
                        [`龍神衣『鳴天葵威』`, 112461]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Ira Gamagoori`, 107957512],
                        [`Nonon Jakuzure`, 107967412],
                        [`Summer Fen`, 103736212],
                    ],  
                    equips: [
                        [`Shackle Regalia`, 153271],
                        [`Symphony Regalia`, 153281],
                        [`Ira's Raid Tracksuit`, 153311],
                        [`Nonon's Raid Tracksuit`, 153321],
                        [`Nonon's Hat`, 153351],
                        [`True 'Shackle Regalia'`, 153381],
                        [`True 'Symphony Regalia'`, 153391],
                        [`Fen's Wavecutter`, 111611]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 63,
        date: `August 31, 2024`,
        article: 
        {
            intro: `Big updates for both JP and Global this time around! 2 new Summer units have been released on JP, alongside various Summer-themed equips! Meanwhile on Global, Summer Iris makes her debut with an exclusive SP. Art, as well as a bunch of new Summer-themed equips too!`,
            new: 
                {
                    units: [
                        [`Summer Mira`, 104007212],
                        [`Summer Celia`, 104017512],
                    ],
                    equips: [
                        [`真『ミラのもりもりマーメイド』`, 112471],
                        [`真『シーリアのぱらいそパラソル』`, 112481],
                        [`ミラのもりもりマーメイド`, 112491],
                        [`ミラのクリティカルビキニ`, 112501],
                        [`ミラのハートフルグラス`, 112511],
                        [`シーリアのぱらいそパラソル`, 112521],
                        [`眷属'sセレクション2024`, 112531],
                        [`シーリアのおめがね`, 112541],
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Summer Iris`, 103727112]
                    ],  
                    equips: [
                        [`Mira’s Essential Purifier`, 111541],
                        [`Kane’s Independence Day`, 111551],
                        [`Serah’s Outdoor Attendant`, 111561],
                        [`Marika’s Mari-Style Mariner`, 111571],
                        [`Lotte’s Friendship Frills`, 111581],
                        [`Valerie’s Friendship Frills`, 111591],
                        [`Iris' Dream Rocket`, 111601]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 64,
        date: `September 5, 2024`,
        article: 
        {
            intro: `Small update on JP with 5 units getting their Dream Awakening!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: [
                        [`Aristela`, 101296212],
                        [`Zoroas`, 101266512],
                        [`Zoldes`, 101306112],
                        [`Olgeth`, 101326312],
                        [`Gaia`, 101716211],
                    ]
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 65,
        date: `September 12, 2024`,
        article: 
        {
            intro: `JP has been updated with a new Raid and various buffs for certain Summer units, each getting respective buffs, True Weapons and their very own SP. Art! Meanwhile on Global, Chapter 25 of the Main Story has been released, alongside Ascended Celia and her farmable equips!`,
            new: 
                {
                    units: [
                        [`Summer Iris`, 103727112],
                        [`Summer Fen`, 103736212],
                        [`Summer Riviera`, 103747112],
                        [`Summer Rosetta`, 103757512]
                    ],
                    equips: [
                        [`真『イリスのドリームロケット』`, 112551],
                        [`真『フェンのビッグウェーブ』`, 112561],
                        [`真『ロゼッタインナー(スリム用)』`, 112571],
                        [`真『リヴィエラ・ダイナマイト』`, 112581],
                        [`封魂の魔晶瓶`, 112591],
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Goddess Celia`, 103767412]
                    ],  
                    equips: [
                        [`True 'Shangri-La'`, 111871],
                        [`Skybound Sword 'Shangri-La'`, 111881],
                        [`Jeweled Finery 'Emerodite'`, 111891],
                    ],
                    dream: []
                }
        }
    },
    {
        id: 66,
        date: `September 21, 2024`,
        article: 
        {
            intro: `A new Rabbits Platoon saga has begun on JP, introducing a new variant of Charlotte and new equips! Meanwhile, Fate collab has been rerun on Global, with the first part bringing buffs for the old Fate units!`,
            new: 
                {
                    units: [
                        [`Fiery Platoon Leader Charlotte`, 104027112]
                    ],
                    equips: [
                        [`真『ディード・イクス・フラム』`, 112601],
                        [`地灼剣『ディード・イクス・フラム』`, 112611]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Illya`, 107737412],
                        [`Rin & Luvia`, 107767112],
                        [`Miyu`, 107747512],
                        [`Shirou Emiya`, 107777212],
                        [`Chloe (Fate)`, 107757112],
                        [`Saber Alter`, 107787512]
                    ],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 67,
        date: `September 28, 2024`,
        article: 
        {
            intro: `Part 1 of The Eminence in Shadow Collab has been released on JP, featuring new units and equips!`,
            new: 
                {
                    units: [
                        [`Shadow`, 108037512],
                        [`Beta`, 108057212],
                    ],
                    equips: [
                        [`真『シャドウの剣』`, 153641],
                        [`真『ベータの弓』`, 153651],
                        [`シャドウのブーツ`, 153661],
                        [`シャドウの剣`, 153671],
                        [`シャドウのスーツ`, 153681],
                        [`ベータの弓`, 153691],
                        [`ベータのスーツ`, 153701],
                        [`ベータのメガネ`, 153711],
                        [`ガンマのぬいぐるみ`, 153721],
                        [`ゼータのぬいぐるみ`, 153731],
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 68,
        date: `October 2, 2024`,
        article: 
        {
            intro: `Small update on JP with the release of 4 new Dream Awakenings. Meanwhile on Global, new Halloween variants of units have been released, alongside new Halloween-themed equips!`,
            new: 
                {
                    units: [],
                    equips: [],
                    dream: [
                        [`Vultee`, 103406212],
                        [`Priscilla`, 103326112],
                        [`Simon`, 103306512],
                        [`Hart (Dark)`, 103286512]
                    ]
                },
            globalUpdate:
                {
                    units: [
                        [`Halloween Charlotte`, 103777112],
                        [`Halloween Valerie`, 103787212],
                        [`Halloween Forte`, 103797512]
                    ],  
                    equips: [
                        [`Lotte's Fluffy Mitts`, 111901],
                        [`Lotte's Clawed Paws`, 111911],
                        [`Valerie's Lizalee Staff`, 111921],
                        [`Trick the Trickster`, 111931],
                        [`Forte's Dolly Dress`, 111941],
                        [`Forte's Funny Hat`, 111951]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 69,
        date: `October 11, 2024`,
        article: 
        {
            intro: `Part 2 of The Eminence in Shadow Collab is released on JP, with new units and equips! Meanwhile on Global, Part 1 of the Summer variants of Fate Collab has been released with new units and equips too!`,
            new: 
                {
                    units: [
                        [`Alpha`, 108047412],
                        [`Delta`, 108067112],
                    ],
                    equips: [
                        [`真『アルファの剣』`, 153741],
                        [`真『デルタのスーツ』`, 153751],
                        [`デルタの髪飾り`, 153761],
                        [`アルファの剣`, 153771],
                        [`アルファの剣`, 153781],
                        [`アルファのブーツ`, 153791],
                        [`デルタのスーツ`, 153801],
                        [`デルタのブーツ`, 153811],
                        [`イプシロンのぬいぐるみ`, 153821],
                        [`イータのぬいぐるみ`, 153831],
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Summer Illya`, 107977212],
                        [`Summer Luvia & Rin`, 108017312]
                    ],  
                    equips: [
                        [`True 'Illya's Swimsuit'`, 153401],
                        [`True 'Luvia's Swimsuit'`, 153431],
                        [`Illya's Swimsuit`, 153461],
                        [`Keepsake Bracelet`, 153471],
                        [`Hecate's Staff`, 153491],
                        [`Arrest Chain`, 153511],
                        [`Rin's Swimsuit`, 153561],
                        [`Luvia's Swimsuit`, 153571]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 70,
        date: `October 19, 2024`,
        article: 
        {
            intro: `Small update for JP as Halloween units have been buffed and received new True Weapons!`,
            new: 
                {
                    units: [
                        [`Halloween Charlotte`, 103777112],
                        [`Halloween Valerie`, 103787212],
                        [`Halloween Forte`, 103797512]
                    ],
                    equips: [
                        [`True 'Lotte's Fluffy Mitts'`, 112621],
                        [`True 'Magical Muscles Valerie'`, 112631],
                        [`True 'Forte's Dolly Dress'`, 112641]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 71,
        date: `October 19, 2024`,
        article: 
        {
            intro: `Bunch of new Dream Awakenings/buffs for KoB units and a new unit from Grand Battle EP3 in JP. Meanwhile on Global, Part 2 of Summer Fate has been released with new units and equips!`,
            new: 
                {
                    units: [
                        [`Vena`, 103927311]
                    ],
                    equips: [],
                    dream: [
                        [`Thetis`, 102506212],
                        [`Duke`, 102526512],
                        [`Rosetta (Water)`, 102676212],
                        [`Juno`, 103226412],
                        [`Rosetta (Earth)`, 103446312],
                        [`Thetis (Light)`, 103586412],
                        [`Riviera (Dark)`, 103576512],
                        [`Vox`, 102516212],
                        [`Berwick`, 102486112],
                        [`Swordsman Berwick`, 102666112],
                        [`Riviera (Earth)`, 102496312],
                        [`Leone`, 102686412]
                    ]
                },
            globalUpdate:
                {
                    units: [
                        [`Summer Miyu`, 107987112],
                        [`Summer Sakura`, 108027512]
                    ],  
                    equips: [
                        [`True 'Miyu's Swimsuit'`, 153411],
                        [`True 'Sakura's Swimsuit'`, 153451],
                        [`Homurabara E. Summer Uniform`, 153481],
                        [`Rule Breaker`, 153501],
                        [`Miyu's Swimsuit`, 153521],
                        [`Miyu's Straw Hat`, 153531],
                        [`Sakura's Swimsuit`, 153611],
                        [`Sakura's Uniform`, 153631]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 72,
        date: `November 2, 2024`,
        article: 
        {
            intro: `Shangri-La collab buffs have been released in JP. Meanwhile on Global, Part 3 of the Summer Fate collab has been released with new units and equips!`,
            new: 
                {
                    units: [
                        [`Sunraku`, 107867112],
                        [`Psyger-0`, 107877412],
                        [`Arthur Pencilgon`, 107887512],
                        [`OiKatzo`, 107897112]
                    ],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Summer Chloe`, 107997412],
                        [`Summer Bazett`, 108007212]
                    ],  
                    equips: [
                        [`True 'Chloe's Swimsuit'`, 153421],
                        [`True 'Bazett's Swimsuit'`, 153441],
                        [`Chloe's Swimsuit`, 153541],
                        [`Chloe's Swim Dress`, 153551],
                        [`Bazett's Swimsuit`, 153581],
                        [`Bazett's Apron`, 153591],
                        [`Fragarach`, 153601],
                        [`Sakura's Mask`, 153621],
                    ],
                    dream: []
                }
        }
    },
    {
        id: 73,
        date: `November 13, 2024`,
        article: 
        {
            intro: `Chapter 31 has been released on JP with a new unit and equips, alongside a new batch of Dream Awakenings! Meanwhile on Global, Chapter 26 has been released with a new unit and equips!`,
            new:
                {
                    units: [
                       [`Phantom Saintess Platina`, 104047412] 
                    ],
                    equips: [
                        [`真『トロイメライ』`, 112651],
                        [`幻聖剣『トロイメライ』`, 112661],
                        [`共鳴装『カンパネラ』`, 112671]
                    ],
                    dream: [
                        [`Nies`, 101666212],
                        [`Sanstone`, 101676112],
                        [`Platina`, 101686412],
                        [`Parlot`, 101696312],
                        [`Dahlia`, 101706512]
                    ]
                },
            globalUpdate:
                {
                    units: [
                        [`Isliid, Human Pinnacle`, 103807412]
                    ],  
                    equips: [
                        [`True 'Rune Sovereign'`, 111971],
                        [`True 'Komplettes Trio'`, 111981],
                        [`Komplettes Trio Replica`, 111991],
                        [`Rune Sovereign Replica`, 112001]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 74,
        date: `November 24, 2024`,
        article: 
        {
            intro: `Another member of the Rabbids Platoon, Natasha, has been released on JP with her respective equips! Meanwhile on Global, Part 1 of The Eminence in Shadow Collab has been released, featuring Shadow and Beta, alongside new equips!`,
            new:
                {
                    units: [
                       [`Natasha: Spiritia 05`, 104057512] 
                    ],
                    equips: [
                        [`真『ライトニング・メアⅢ』`, 112681],
                        [`紫雷の戦棍『ライトニング・メアⅢ』`, 112691]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Shadow`, 108037512],
                        [`Beta`, 108057212],
                    ],  
                    equips: [
                        [`True 'Shadow's Sword'`, 153641],
                        [`True 'Beta's Bow'`, 153651],
                        [`Shadow's Boots`, 153661],
                        [`Shadow's Sword`, 153671],
                        [`Shadow's Bodysuit`, 153681],
                        [`Beta's Bow`, 153691],
                        [`Beta's Bodysuit`, 153701],
                        [`Beta's Spectacles`, 153711],
                        [`Gamma Plushie`, 153721],
                        [`Zeta Plushie`, 153731],
                    ],
                    dream: []
                }
        }
    },
    {
        id: 75,
        date: `December 3, 2024`,
        article: 
        {
            intro: `Chapter 32 has debut on JP with Orvell D. Granverge as the newest unit, alongside new equips associated to him!`,
            new:
                {
                    units: [
                       [`Orvell D. Granverge`, 104067412] 
                    ],
                    equips: [
                        [`超『エクス・クランベルジュ』`, 112701],
                        [`真『エクス・クランベルジュ』`, 112711],
                        [`双王剣『エクス・クランベルジュ』`, 112721],
                        [`煌王鎧『アルファダイン』`, 112731]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 76,
        date: `December 8, 2024`,
        article: 
        {
            intro: `JP has received more Dream Awakenings and buffs, while Part 2 of the Eminence in Shadow collab has been released on Global!`,
            new:
                {
                    units: [],
                    equips: [],
                    dream: [
                        [`Cestina (Earth)`, 102986312],
                        [`Liza`, 103006412],
                        [`Victoire`, 103096412],
                        [`Orvell (Fire)`, 103456112],
                        [`Cestina (Light)`, 103466412],
                        [`Orvell`, 103026412],
                        [`Luahn`, 103046512],
                        [`Angelas`, 103066112],
                        [`Eagle`, 103076312],
                        [`Bakoo`, 103086512]
                    ]
                },
            globalUpdate:
                {
                    units: [
                        [`Alpha`, 108047412],
                        [`Delta`, 108067112],
                    ],  
                    equips: [
                        [`True 'Alpha's Sword'`, 153741],
                        [`True 'Delta's Bodysuit'`, 153751],
                        [`Delta's Hairpiece`, 153761],
                        [`Alpha's Sword`, 153771],
                        [`Alpha's Bodysuit`, 153781],
                        [`Alpha's Boots`, 153791],
                        [`Delta's Bodysuit`, 153801],
                        [`Delta's Boots`, 153811],
                        [`Epsilon Plushie`, 153821],
                        [`Eta Plushie`, 153831]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 77,
        date: `December 16, 2024`,
        article: 
        {
            intro: `JP debuts a new saga for its 8th Anniversary along with a new unit and equips!`,
            new:
                {
                    units: [
                        [`Menas`, 104077112]
                    ],
                    equips: [
                        [`真『マルチプル』`, 112741],
                        [`万能刃『マルチプル』`, 112751]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 78,
        date: `December 17, 2024`,
        article: 
        {
            intro: `Christmas has come to Global with a variety of new equips! Moreover, Jay's event has also been released for the first time with his equips!`,
            new:
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [
                        [`Neon Bayonet`, 111401],
                        [`Blue Jacket`, 111411],
                        [`Innocent Santa Lotte`, 110921],
                        [`Thoughtful Santa Valerie`, 110931],
                        [`Lethargic Snow Marika`, 110941],
                        [`Serving Serah Doll`, 110951],
                        [`Restless Reindeer Arcana`, 110961],
                        [`Garland Sword 'Star Light'`, 112081],
                        [`Resolute Reindeer Noel`, 112091],
                        [`Perfect Santa Emperor`, 112101],
                        [`Loving Snow Princess`, 112111],
                        [`Hunting Santa Jay`, 112121],
                        [`Watchful Reindeer Canaria`, 112131],
                    ],
                    dream: []
                }
        }
    },
    {
        id: 79,
        date: `January 3, 2025`,
        article: 
        {
            intro: `HAPPY NEW YEAR! New units and equips have been introduced with the rerun of Tensura collab on JP, alongside many existing buffs. Meanwhile on Global, Chapter 27 of the Main Story has been released with Ultimate Paladin Roy and his equips!`,
            new:
                {
                    units: [
                        [`Demon Lord Rimuru (New Years)`, 108077512],
                        [`Shuna (New Years)`, 108087412]
                    ],
                    equips: [
                        [`フィリルの平々トナカイ`, 112761],
                        [`アズエルのワイルドサンタ`, 112771],
                        [`ヴェナのモフモフサンタ`, 112781],
                        [`ルアーナのうさンタドレス`, 112791],
                        [`聖紋剣『ホーリーマスター』`, 112801],
                        [`雪逢刃『スノーシティ』`, 112811],
                        [`翠輪装『ファイザード』`, 112821],
                        [`氷竜珠『エルスフィア』`, 112831],
                        [`真『魔王リムルの侍刀』`, 153841],
                        [`真『シュナの着物』`, 153851],
                        [`魔王リムルの侍刀`, 153861],
                        [`魔王リムルの袴`, 153871],
                        [`魔王リムルのぬいぐるみ`, 153881],
                        [`シュナの着物`, 153891],
                        [`シュナのアクセサリー`, 153901],
                        [`シュナのぬいぐるみ`, 153911],
                        [`スライムリムルのぬいぐるみ`, 153921],
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Ultimate Paladin Roy`, 103827212]
                    ],  
                    equips: [
                        [`Blaze Fan 'Magnigate'`, 110051],
                        [`Magnigate EV`, 110061],
                        [`True 'Gladius'`, 112051],
                        [`Sainted Sword 'Gladius'`, 112061],
                        [`Sainted Armor 'Aeolus'`, 112071],
                    ],
                    dream: []
                }
        }
    },
    {
        id: 80,
        date: `January 9, 2025`,
        article: 
        {
            intro: `Part 2 of the Tensura Collab rerun has been released, alongside Veldora and Shizu with a variety of new equips! Meanwhile on Global, a Shangri-La Collab rerun has been announced with buffs for the 4 existing Collab units!`,
            new:
                {
                    units: [
                        [`Veldora`, 108097312],
                        [`Shizu`, 108107112]
                    ],
                    equips: [
                        [`真『ヴェルドラのマント』`, 153931],
                        [`真『シズの剣』`, 153941],
                        [`ヴェルドラのマント`, 153951],
                        [`ヴェルドラの手袋`, 153961],
                        [`シズの剣`, 153971],
                        [`シズの服`, 153981],
                        [`シズのぬいぐるみ`, 153991],
                        [`ヴェルドラのぬいぐるみ`, 154001],
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 81,
        date: `January 11, 2025`,
        article: 
        {
            intro: `Small update this time on JP as 5 new equips have been released, obtainable from tickets from the regularly recurring Human Ruins.`,
            new:
                {
                    units: [],
                    equips: [
                        [`渇望の杖『オウンサースト』`, 112841],
                        [`人族史書『ロストピア』`, 112851],
                        [`合理の剣『アンダーマキナ』`, 112861],
                        [`叡智の薬瓶『オッドマーク』`, 112871],
                        [`静寂の兜『ウィルシード』`, 112881]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 82,
        date: `January 18, 2025`,
        article: 
        {
            intro: `Small update on JP with a new Mines boss and new equip!`,
            new:
                {
                    units: [],
                    equips: [
                        [`絶耀具『マグナヘリオス』`, 112891]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 83,
        date: `January 25, 2025`,
        article: 
        {
            intro: `New Valentines units have made their debut on Global, along with a new Valentines farmable equip too!`,
            new:
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Liza (Valentines)`, 103857312],
                        [`Cestina (Valentines)`, 103867212],
                    ],  
                    equips: [
                        [`Silk Loving Broom 'Etoile V'`, 112261]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 84,
        date: `February 6, 2025`,
        article: 
        {
            intro: `Star Swordswoman Rosetta makes her debut with a new Main Story chapter and new equips! Meanwhile on Global, Hart the Fabricator alongside Chapter 28 has been released!`,
            new:
                {
                    units: [
                        [`Star Swordswoman Rosetta`, 104087212]
                    ],
                    equips: [
                        [`真『7thアルテミス』`, 112901],
                        [`星章剣『7thアルテミス』`, 112911],
                        [`星翔衣『アルペンステラ』`, 112921],
                        ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Hart the Fabricator`, 103847512],
                    ],  
                    equips: [
                        [`True 'Asgard's Casket'`, 112201],
                        [`Asgard's Casket`, 112211],
                        [`Ritual Greaves 'Blenfate'`, 112221]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 85,
        date: `February 17, 2025`,
        article: 
        {
            intro: ``,
            new:
                {
                    units: [],
                    equips: [
                        [`沈黙の杖『ドーンプライヤー』`, 112931],
                        [`神族史書『レジェンズ』`, 112941],
                        [`比翼の剣『ヘブンズセレナ』`, 112951],
                        [`神秘の秘薬『ハーミットペイン』`, 112961],
                        [`摂理の兜『ヴィジョンズオール』`, 112971],
                        [`炎焔杖『アグニマグニ』`, 112981],
                        [`真『ドラゴンエイジ』`, 112991]
                        ],
                    dream: [
                        [`Rohst`, 101996511],
                        [`Saar`, 101236212],
                        [`Pola`, 101246312],
                        [`Leon`, 101256512]
                    ]
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 86,
        date: `February 22, 2025`,
        article: 
        {
            intro: `New unit has debut on JP with her respective equips!`,
            new:
                {
                    units: [
                        [`Misette`, 104037412]
                    ],
                    equips: [
                        [`真『ノーリプライ』`, 113001],
                        [`月仮面『ノーリプライ』`, 113011]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 87,
        date: `February 26, 2025`,
        article: 
        {
            intro: `New Tensura units have been released on Global, alongside new equips too!`,
            new:
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Demon Lord Samurai Rimuru`, 108077512],
                        [`Shuna`, 108087412]                        
                    ],  
                    equips: [
                        [`True 'Demon Lord Rimuru's Katana'`, 153841],
                        [`True 'Shuna's Kimono'`, 153851],
                        [`Demon Lord Rimuru's Katana`, 153861],
                        [`Demon Lord Rimuru's Hakama`, 153871],
                        [`Demon Lord Rimuru Plushie`, 153881],
                        [`Shuna's Kimono`, 153891],
                        [`Shuna's Accessory`, 153901],
                        [`Shuna Plushie`, 153911],
                        [`Slime Rimuru Plushie`, 153921]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 88,
        date: `March 8, 2025`,
        article: 
        {
            intro: `New Rurouni Kenshin collab has been released on JP with new units and equips! Meanwhile on Global, Part 2 of the Tensura Collab rerun has been released with 2 new Ascended units and many collab-limited equips too!`,
            new:
                {
                    units: [
                        [`Himura Kenshin`, 108117412],
                        [`Sagara Sanosuke`, 108127212]
                    ],
                    equips: [
                        [`真『逆刃刀』`, 154011],
                        [`真『相楽左之助の服』`, 154021],
                        [`逆刃刀`, 154031],
                        [`緋村剣心の着物`, 154041],
                        [`極『緋村剣心の着物』`, 154050],
                        [`緋村剣心の草履`, 154061],
                        [`斬馬刀`, 154071],
                        [`相楽左之助の鉢巻`, 154081],
                        [`相楽左之助の服`, 154091],
                        [`明神弥彦の竹刀`, 154101],
                        [`明神弥彦の着物`, 154111],
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Veldora`, 108097312],
                        [`Shizu`, 108107112]                        
                    ],  
                    equips: [
                        [`True 'Veldora's Cape'`, 153931],
                        [`True 'Shizu's Sword'`, 153941],
                        [`Veldora's Cape`, 153951],
                        [`Veldora's Gloves`, 153961],
                        [`Shizu's Sword`, 153971],
                        [`Shizu's Outfit`, 153981],
                        [`Shizu Plushie`, 153991],
                        [`Veldora Plushie`, 154001],
                    ],
                    dream: []
                }
        }
    },
    {
        id: 89,
        date: `March 16, 2025`,
        article: 
        {
            intro: `Part 2 of the Rurouni Kenshin Collab has been released on JP with new units and equips!`,
            new:
                {
                    units: [
                        [`Saito Hajime`, 108137512],
                        [`Shishio Makoto`, 108147112]
                    ],
                    equips: [
                        [`侵蝕の杖『ディープレイ』`, 113021],
                        [`魔族史書『デスコード』`, 113031],
                        [`千骸の剣『ウォークライ』`, 113041],
                        [`選定の薬瓶『アナザーワン』`, 113051],
                        [`断絶の兜『ルナティックラグ』`, 113061],
                        [`真『斎藤一の刀』`, 154121],
                        [`真『志々雄真実の刀』`, 154131],
                        [`斎藤一の刀`, 154141],
                        [`斎藤一の服`, 154151],
                        [`極『斎藤一の服』`, 154160],
                        [`斎藤一の靴`, 154171],
                        [`志々雄真実の刀`, 154181],
                        [`志々雄真実の着物`, 154191],
                        [`神谷薫の着物`, 154201],
                        [`神谷薫の髪飾り`, 154211],
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    },
    {
        id: 90,
        date: `March 22, 2025`,
        article: 
        {
            intro: `Grand Crusade has finally come to Global, alongside Azel and his True Weapon. As there are many new Fusion Equips with varying effects, they will not be listed on the Equips page, however, they are listed on the new "Grand Crusade" tab!`,
            new:
                {
                    units: [],
                    equips: [],
                    dream: []
                },
            globalUpdate:
                {
                    units: [
                        [`Azel`, 103917112]
                    ],  
                    equips: [
                        [`True 'Twin Falcons`, 112421]
                    ],
                    dream: []
                }
        }
    },
    {
        id: 91,
        date: `March 27, 2025`,
        article: 
        {
            intro: `Grand Crusade EP4 debuts in JP with a new unit!`,
            new:
                {
                    units: [
                        [`Erens`, 104097312]
                    ],
                    equips: [
                        [`真『アースクレイム』`, 113071]
                    ],
                    dream: []
                },
            globalUpdate:
                {
                    units: [],  
                    equips: [],
                    dream: []
                }
        }
    }
]

export function getNews() {
    return NewsArticles;
}

//Using == instead of === to match string to num
export function getNewsById(id) {
    return NewsArticles.find(
        newsArticle => newsArticle.date === id
    );
}


